//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Component, Vue } from "nuxt-property-decorator";
@Component({
  middleware: ["authenticated"],
})
export default class Projects extends Vue {
  loadingDeleteNotifications = false;
  showNotifications = false;
  fetchNotificationsIn = false;
  notifications = [];
  changePassword = {
    show: false,
    oldPass: "",
    newPass: "",
    cnfNewPass: "",
    email: this.$auth.user.user.email,
    app: this.$auth.user.user.app,
    validationMsg: "",
  };
  created() {
    this.fetchNotifications();
    this.fetchNotificationsIn = setInterval(() => {
      this.fetchNotifications();
    }, 90000);
  }
  beforeDestroy() {
    try {
      clearInterval(this.fetchNotificationsIn);
      console.log("clearedInterval fetchNotificationsIn");
    } catch (error) {
      console.log(error);
    }
  }
  async fetchNotifications() {
    try {
      var res = await this.$axios.get(
        `${process.env.authURL}/notification?app=${
          process.env.APP_TYPE ? process.env.APP_TYPE : "aistudio"
        }&vendor=${this.$auth.user.user.AssociatedVendor["vendorName"]}&user=${
          this.$auth.user.user.email
        }&limit=1000&sort=createdAt%20DESC`
      );
      this.notifications = res.data.results;
      // this.notifications = [
      //   {
      //       "createdAt": 1645104957712,
      //       "updatedAt": 1645104957712,
      //       "id": "620e4f3d29a78f74837b744a",
      //       "title": "testing111111",
      //       "description": "testing1111 email",
      //       "vendor": "demo",
      //       "user": "souma.ghosh@streamoid.com",
      //       "type": "info",
      //       "app": "stagingAIstudio",
      //       "action": "none"
      //   },
      //   {
      //       "createdAt": 1645104735306,
      //       "updatedAt": 1645104735306,
      //       "id": "620e4e5f29a78ff5e87b7449",
      //       "title": "testing111111",
      //       "description": "testing1111 email",
      //       "vendor": "demo",
      //       "user": "souma.ghosh@streamoid.com",
      //       "type": "info",
      //       "app": "stagingAIstudio",
      //       "action": "none"
      //   }
      // ]
    } catch (error) {
      this.$message.error("Unable to fetch notifications, Network Error !");
    }
  }
  async deleteNotification(notification) {
    this.notifications.forEach(async (notf, notfIndex) => {
      if (notf.id == notification.id) {
        try {
          this.loadingDeleteNotifications = true;
          await this.$axios.delete(
            `${process.env.authURL}/notification/${notf.id}`
          );
          this.notifications.splice(notfIndex, 1);
          this.loadingDeleteNotifications = false;
        } catch (error) {
          this.loadingDeleteNotifications = false;
        }
      }
    });
  }
  handleUserMenuClick = (action) => {
    if (action.key == "logout") {
      this.$router.push("/auth/logout");
    }
    if (action.key == "users") {
      this.$router.push("/auth/users");
    }
    if (action.key == "videos") {
      this.$router.push("/auth/videos");
    }
    if (action.key == "guide") {
      window.open("https://streamoid.gitbook.io/ai-studio/", "_newtab");
    }
    if (action.key == "changepassword") {
      this.changePassword.show = true;
    }
  };
  handleChangePassword = () => {
    let { oldPass, newPass, cnfNewPass, email, app } = this.changePassword;

    // Add Password validations below. The order matters!
    if (!oldPass) return this.setValidationMsg("Old password cannot be empty!");
    if (!newPass) return this.setValidationMsg("New password cannot be empty!");
    if (newPass.length < 8)
      return this.setValidationMsg("Password must be atleast 8 characters!");
    if (newPass === oldPass)
      return this.setValidationMsg(
        "New password cannot be same as old password!"
      );
    if (cnfNewPass !== newPass)
      return this.setValidationMsg("The passwords don't match!");

    const formData = new FormData();
    formData.append("oldPass", oldPass);
    formData.append("newPass", newPass);
    formData.append("email", email);
    formData.append("app", app);
    this.$axios
      .post(`${process.env.SERVER_BASE_URL}/user/changepassword`, formData)
      .then((res) => {
        // console.log("changepassword => ", res);
        if (res?.data?.status === "failed") {
          this.setValidationMsg(res.data.message);
          return;
        }
        if (res?.data?.status === "success") {
          this.changePassword.oldPass = "";
          this.changePassword.newPass = "";
          this.changePassword.cnfNewPass = "";
          this.$message.success("Password updated!");
          this.changePassword.show = false;
        }
      })
      .catch((err) => {
        console.log("changepassword error", err);
        this.setValidationMsg("Oops! Something went wrong!");
      });
  };
  setValidationMsg = (msg) => {
    this.changePassword.validationMsg = msg;
    setTimeout(() => {
      this.changePassword.validationMsg = "";
    }, 3000);
  };
}
