




















































import {
    Component,
    Vue
} from 'nuxt-property-decorator'
@Component({
    components: {

    }
})
export default class Projects extends Vue {

    sendQuery() {
        var email = "prod-support@streamoid.com";
        location.href = "mailto:" + email;
    }
    created() {

    }

}
