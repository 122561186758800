import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1df86b7e = () => interopDefault(import('../pages/auth/login/index.vue' /* webpackChunkName: "pages/auth/login/index" */))
const _c50dd670 = () => interopDefault(import('../pages/auth/logout/index.vue' /* webpackChunkName: "pages/auth/logout/index" */))
const _3efac5e1 = () => interopDefault(import('../pages/auth/register/index.vue' /* webpackChunkName: "pages/auth/register/index" */))
const _75072af6 = () => interopDefault(import('../pages/auth/signUp/index.vue' /* webpackChunkName: "pages/auth/signUp/index" */))
const _1c3c23c0 = () => interopDefault(import('../pages/auth/users/index.vue' /* webpackChunkName: "pages/auth/users/index" */))
const _7d1de8f7 = () => interopDefault(import('../pages/auth/verify/index.vue' /* webpackChunkName: "pages/auth/verify/index" */))
const _cf7dddd4 = () => interopDefault(import('../pages/auth/videos/index.vue' /* webpackChunkName: "pages/auth/videos/index" */))
const _568b685a = () => interopDefault(import('../pages/image-tagging/create-new/index.vue' /* webpackChunkName: "pages/image-tagging/create-new/index" */))
const _3f3a78a6 = () => interopDefault(import('../pages/image-tagging/create-new-project/index.vue' /* webpackChunkName: "pages/image-tagging/create-new-project/index" */))
const _759cc258 = () => interopDefault(import('../pages/image-tagging/examples/index.vue' /* webpackChunkName: "pages/image-tagging/examples/index" */))
const _5400aa5d = () => interopDefault(import('../pages/multi-label/create-new/index.vue' /* webpackChunkName: "pages/multi-label/create-new/index" */))
const _7824377a = () => interopDefault(import('../pages/image-tagging/projects/_project/index.vue' /* webpackChunkName: "pages/image-tagging/projects/_project/index" */))
const _bb7787d2 = () => interopDefault(import('../pages/multi-label/projects/_project/index.vue' /* webpackChunkName: "pages/multi-label/projects/_project/index" */))
const _3413d3e7 = () => interopDefault(import('../pages/image-tagging/projects/_project/analysis/index.vue' /* webpackChunkName: "pages/image-tagging/projects/_project/analysis/index" */))
const _48d42146 = () => interopDefault(import('../pages/image-tagging/projects/_project/datasets/index.vue' /* webpackChunkName: "pages/image-tagging/projects/_project/datasets/index" */))
const _64c2afea = () => interopDefault(import('../pages/multi-label/projects/_project/analysis/index.vue' /* webpackChunkName: "pages/multi-label/projects/_project/analysis/index" */))
const _7982fd49 = () => interopDefault(import('../pages/multi-label/projects/_project/datasets/index.vue' /* webpackChunkName: "pages/multi-label/projects/_project/datasets/index" */))
const _b9af7090 = () => interopDefault(import('../pages/image-tagging/projects/_project/datasets/_dataset/index.vue' /* webpackChunkName: "pages/image-tagging/projects/_project/datasets/_dataset/index" */))
const _10934ada = () => interopDefault(import('../pages/image-tagging/projects/_project/models/_model/index.vue' /* webpackChunkName: "pages/image-tagging/projects/_project/models/_model/index" */))
const _504d3f15 = () => interopDefault(import('../pages/multi-label/projects/_project/datasets/_dataset/index.vue' /* webpackChunkName: "pages/multi-label/projects/_project/datasets/_dataset/index" */))
const _e8270e92 = () => interopDefault(import('../pages/multi-label/projects/_project/models/_model/index.vue' /* webpackChunkName: "pages/multi-label/projects/_project/models/_model/index" */))
const _9116e6f2 = () => interopDefault(import('../pages/image-tagging/projects/_project/models/_model/analysis/index.vue' /* webpackChunkName: "pages/image-tagging/projects/_project/models/_model/analysis/index" */))
const _86f6b16c = () => interopDefault(import('../pages/multi-label/projects/_project/models/_model/analysis/index.vue' /* webpackChunkName: "pages/multi-label/projects/_project/models/_model/analysis/index" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth/login",
    component: _1df86b7e,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _c50dd670,
    name: "auth-logout"
  }, {
    path: "/auth/register",
    component: _3efac5e1,
    name: "auth-register"
  }, {
    path: "/auth/signUp",
    component: _75072af6,
    name: "auth-signUp"
  }, {
    path: "/auth/users",
    component: _1c3c23c0,
    name: "auth-users"
  }, {
    path: "/auth/verify",
    component: _7d1de8f7,
    name: "auth-verify"
  }, {
    path: "/auth/videos",
    component: _cf7dddd4,
    name: "auth-videos"
  }, {
    path: "/image-tagging/create-new",
    component: _568b685a,
    name: "image-tagging-create-new"
  }, {
    path: "/image-tagging/create-new-project",
    component: _3f3a78a6,
    name: "image-tagging-create-new-project"
  }, {
    path: "/image-tagging/examples",
    component: _759cc258,
    name: "image-tagging-examples"
  }, {
    path: "/multi-label/create-new",
    component: _5400aa5d,
    name: "multi-label-create-new"
  }, {
    path: "/image-tagging/projects/:project",
    component: _7824377a,
    name: "image-tagging-projects-project"
  }, {
    path: "/multi-label/projects/:project",
    component: _bb7787d2,
    name: "multi-label-projects-project"
  }, {
    path: "/image-tagging/projects/:project?/analysis",
    component: _3413d3e7,
    name: "image-tagging-projects-project-analysis"
  }, {
    path: "/image-tagging/projects/:project?/datasets",
    component: _48d42146,
    name: "image-tagging-projects-project-datasets"
  }, {
    path: "/multi-label/projects/:project?/analysis",
    component: _64c2afea,
    name: "multi-label-projects-project-analysis"
  }, {
    path: "/multi-label/projects/:project?/datasets",
    component: _7982fd49,
    name: "multi-label-projects-project-datasets"
  }, {
    path: "/image-tagging/projects/:project?/datasets/:dataset",
    component: _b9af7090,
    name: "image-tagging-projects-project-datasets-dataset"
  }, {
    path: "/image-tagging/projects/:project?/models/:model",
    component: _10934ada,
    name: "image-tagging-projects-project-models-model"
  }, {
    path: "/multi-label/projects/:project?/datasets/:dataset",
    component: _504d3f15,
    name: "multi-label-projects-project-datasets-dataset"
  }, {
    path: "/multi-label/projects/:project?/models/:model",
    component: _e8270e92,
    name: "multi-label-projects-project-models-model"
  }, {
    path: "/image-tagging/projects/:project?/models/:model/analysis",
    component: _9116e6f2,
    name: "image-tagging-projects-project-models-model-analysis"
  }, {
    path: "/multi-label/projects/:project?/models/:model/analysis",
    component: _86f6b16c,
    name: "multi-label-projects-project-models-model-analysis"
  }, {
    path: "/",
    component: _482400f4,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
